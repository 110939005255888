body {
  margin: 0;
  background-color: var(--background);
  font-family: 'Montserrat', sans-serif, 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Enabled Focus Settings */
:enabled:focus {
  border-color: gray 1rem !important;
  box-shadow: none !important;
}

:not(.p-disabled).p-focus {
  border-color: gray 1rem !important;
  box-shadow: none !important;
}

.p-inputswitch.p-focus .p-inputswitch-slider {
  box-shadow: none !important;
}

/* Footer */
.wrapper {
  flex: 1 !important;
}

:-moz-focusring {
  outline: none;
}

.page-wrap {
  min-height: 55vh !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  /* equal to footer height */
}

.page-content {
  background-color: #f5a800;
  padding: 0 !important;
  margin: 0 !important;
}

.container {
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg,
  .container-xl {
    min-width: 1140px;
  }
}

@media (min-width: 992px) {

  .container,
  .container-sm,
  .container-md,
  .container-lg {
    max-width: 960px;
  }
}

@media (min-width: 768px) {

  .container,
  .container-sm,
  .container-md {
    max-width: 720px;
  }
}

.page-wrap:before {
  content: "" !important;
  display: block !important;
  visibility: hidden;
}

.header,
.page-wrap::before {
  color: #fff;
  min-height: 35vh !important;
  padding: 0 !important;
  margin: 0 !important;
  /* .push must be the same height as footer */
}

.page-wrap:after {
  content: "" !important;
  display: block !important;
  visibility: hidden;
}

.footer,
.page-wrap:after {
  background-color: #231f20;
  color: #fff;
  min-height: 10vh !important;
  padding: 0 !important;
  margin: 0 !important;
  /* .push must be the same height as footer */
}

/* Card */
.card-shadow {
  -webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
  box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.75);
}

/* DataTable */
.datatable-test tr:nth-child(even) {
  background-color: rgba(0, 0, 0, 0.05);
}

.datatable-test tr:nth-child(odd) {
  background-color: rgb(240, 239, 244);
}

/* BreadCrumb */
.breadcrumb .p-menuitem-text {
  color: #6c757d !important;
}

/* ShadowCard Title */
#textbox {display:flex; flex-flow:row wrap;}

.alignleft {
  width: 33.33333%;
  text-align: left;
}
.aligncenter {
  width: 66.66666%;
  text-align: center;
}
.alignright {
  width: 33.33333%;
  text-align: right;
}
/* Text Elipsis */
.text-elipsis-portal {
  display: "inline-block";
  white-space: "nowrap";
  overflow: "hidden";
  text-overflow: "ellipsis";
  max-width: "100%";
}

.haberler .p-scrollpanel-content {
  overflow: hidden;
}

.move-icon {
  content: '';
    display: block;
    height: 1em;
    width: 1em;
    background-position: initial;
    background-size: cover;
    background-repeat: no-repeat;
}